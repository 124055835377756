.invite-teacher-container {
  background-image: url("./background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .step {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
        color: #004b77;
        opacity: 0.2;
      }
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 72px;
        line-height: 90px;
        letter-spacing: -0.02em;
        color: #004b77;
      }
      .desc {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #007dc6;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sbmt-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      background: #ffffff;
      border-radius: 10px;
      align-self: flex-start;
      margin-top: 45px;
      font-size: 16px;
      line-height: 24px;
      color: #00649E;
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-weight: 700;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .field {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 67px;
        letter-spacing: -0.02em;
        color: #004b77;
      }
      input:focus {
        outline: none;
      }
      .input-field {
        border: 0px;
        border-bottom: 5px solid #004b77;
        height: 60px;
        width: 105px;
        cursor: pointer;
        background-color: inherit;
        font-size: 48px;
        color: #004b77;
        margin: 0px 10px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.02em;
        caret-color: transparent;
      }
      .input-field-1 {
        border: 0px;
        border-bottom: 5px solid #004b77;
        cursor: pointer;
        height: 60px;
        width: 147px;
        font-size: 48px;
        color: inherit;
        background-color: inherit;
        margin: 0px 10px 0px 0px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.02em;
        caret-color: transparent;
      }
      .big {
        border: 0px;
        border-bottom: 5px solid #004b77;
        height: 60px;
        width: 380px;
        font-size: 48px;
        color: inherit;
        background-color: inherit;
        margin: 0px 10px 0px 0px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.02em;
      }
      .medium {
        border: 0px;
        border-bottom: 5px solid #004b77;
        height: 60px;
        width: 315;
        font-size: 48px;
        color: inherit;
        background-color: inherit;
        margin: 0px 10px 0px 0px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.02em;
      }
      .input-field-2 {
        border: 0px;
        border-bottom: 5px solid #004b77;
        height: 60px;
        width: 55px;
        background-color: inherit;
        cursor: pointer;
        font-size: 48px;
        color: inherit;
        margin: 0px 10px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.02em;
        caret-color: transparent;
      }
      .input-field-3 {
        border: 0px;
        border-bottom: 5px solid #004b77;
        height: 60px;
        width: 90px;
        background-color: inherit;
        font-size: 48px;
        color: inherit;
        cursor: pointer;
        margin: 0px 10px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -0.02em;
        caret-color: transparent;
      }
      .color-blue {
        color: #4DBDFF;
        caret-color: transparent;
      }
    }
  }
}
::placeholder { 
  color: #004B77;
  opacity: 0.2;
  
}