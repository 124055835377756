.dialog-container {
  .list-items {
    padding: 20px;
    .font-size-28 {
      font-size: 28px;
      cursor: pointer;
    }
  }
  .m-0 {
    margin: 0px !important;
    width: 100% !important;
    color: #004b77;
    font-weight: 700;
  }
  .MuiGrid-item {
    min-width: 45px;
  }
}
.dialog-container .MuiPopover-paper {
  margin-top: 14px !important;
}