.accordian-container-2 {
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  width: inherit;
  height: 80vh;

  .accordian-container {
    background-color: inherit;
    box-shadow: none;
    border-radius: 0px !important;
    width: inherit;
    margin-top: -27px;

    .MuiAccordionSummary-root {
      padding: 5px 0px !important;
      box-shadow: none;
    }
    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }

    // .remove-before::before {
    //   top: 0px;
    //   left: 0;
    //   right: 0;
    //   height: 1px;
    //   content: "";
    //   opacity: 0;
    //   position: absolute;
    //   background-color: #fff;
    // }
    .title-project-overview {
      color: #667085;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      .profile-image {
        width: 3vw;
        height: 3vw;
        border-radius: 12px;
        border: 2px solid #eaecf0;
        background: lightgray;
        margin-right: 10px;
      }
    }

    .accordion-details {
      display: flex;
      flex-direction: column;
      padding: 0px !important;
      width: 100%;
      padding-top: 10px !important;
      justify-content: center;
      font-family: "Gotham-Light", "Courier New", Courier, monospace;

      .Overview-txt {
        font-weight: 100;
        font-size: 14px;
        line-height: 20px;
        color: #475467;
        background: #fcfcfd;
        border-radius: 4px;
        margin-bottom: 5px;
        padding: 8px;
        width: 89%;
      }
      .details-card {
        border-radius: 10px;
        border: 1px solid #eaecf0;
        background: #fcfcfd;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        .left-content-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .standard-title-txt {
            color: #007dc6;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 5px;
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          }
          .standard-description-txt {
            color: #344054;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
          }
        }
      }
    }
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
    background: inherit;
    border-radius: 0px !important;
    margin-top: 0px;
  }

  .Mui-expanded {
    margin: 0px !important;
  }
  .MuiAccordion-root:before {
    content: unset !important;
  }
  .MuiAccordion-rounded {
    border-bottom: 1px solid #EAECF0 !important;
  }
  // .MuiAccordionSummary-root::before {
  //   top: -1px;
  //   left: 0;
  //   right: 0;
  //   height: 1px;
  //   content: "";
  //   opacity: 1;
  //   position: absolute;
  //   transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  //     background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  //   background-color: rgba(0, 0, 0, 0.12);
  // }

  .title-steps {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    font-family: "Gotham", "Courier New", Courier, monospace;
  }

  .pl-16 {
    padding-left: 25px;
  }

  .steps-container {
    padding-left: 35px;
    padding-top: 18px;

    .course-step {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 20px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #475467;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      }
    }
  }
}
/* width */
.accordian-container-2::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.accordian-container-2::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.accordian-container-2::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 8px;
}

/* Handle on hover */
.accordian-container-2::-webkit-scrollbar-thumb:hover {
  background: white;
}
