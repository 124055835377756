.support-container {
  margin: 0px !important;
  padding: 0px 20px !important;
  padding-right: 0px !important;
  display: flex;
  flex-direction: column;
  .min-h-340 {
    min-height: 340px;
    font-weight: 325;
    font-size: 20px;
    line-height: 24px;
    font-family: "GOTHAM-Medium1" "Courier New", Courier, monospace;
  }
  .suport-content {
    display: flex;
    min-height: 90vh;
    width: inherit;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
  }
  .pt-20 {
    padding-top: 38px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .header1 {
    .img {
      width: 100%;
      height: 100%;
    }

    .content-1 {
      height: auto;
      width: auto;
      position: relative;

      .content {
        position: absolute;
        top: 16%;
        bottom: 16%;
        left: 2%;
        width: 59%;

        .pad {
          padding-bottom: 3.2552083333333335vw;
        }

        .pad1 {
          padding-bottom: 2.255vw !important;

        }

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 3.125vw;
          line-height: unset !important;
          letter-spacing: -0.02em;
          color: #00324f;
        }

        .desc {
          font-weight: 325;
          font-size: 1.3020833333333333vw;
          color: #475467;
          line-height: 25px;
        }
      }
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 3.5vw;
      letter-spacing: -0.02em;
      color: #00324f;
    }

    .desc {
      font-weight: 100;
      font-size: 1.5vw;
      color: #475467;
    }
  }
}

.back-container {
  padding: 10px 0px;
  .img-div {
    padding: 10px;
  }

}

.blogs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #242a32;
  }
  .top-title {
    color: #242a32 !important;
    font-size: 32px !important;
  }
  .menu {
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    color: #007dc6;
    padding-top: 24px;
    padding-left: 8px;
  }
  .menu:hover {
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    color: #007dc6;
    padding-top: 24px;
    text-decoration: underline !important;
    cursor: pointer;
    padding-left: 8px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: inherit;
  align-self: flex-end;

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 500px;
    background: #ffffff;
    border-radius: 10px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #242a32;
    }

    .desc {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #007dc6;
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .btn {
        padding: 12px 24px;
        gap: 16px;
        height: 48px;
        background: #007dc6;
        border-radius: 10px;
        color: #ffffff;
        width: 49%;
        font-size: 16px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-weight: 700;
      }
    }
  }
}