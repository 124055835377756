.addcredit-container {
  margin: 0px !important;
  padding: 0px !important;
  width: auto !important;

  .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #344054;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }

  .card0 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    .left {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }

    .btn-plus {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      height: 48px;
      background: #007dc6;
      border-radius: 10px;
      color: #ffffff;
    }



    .number {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #ffffff;
    }

    .btn-text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      text-decoration: none;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;

    }

    .apply-credit0101 {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

    }
  }

  .card-item-main {
    margin: 0px !important;
    padding: 0px !important;
    margin-top: 6px !important;

    .card-item {
      border: 1px solid #eaecf0;
      border-radius: 10px;
      position: relative;
      padding: 10px;

      .discount {
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: #feede6;
        padding: 12px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #f5814d;
        font-style: normal;
        font-weight: 400;
        font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
      }

      .img {
        margin-bottom: 25px;
      }

      .card-main-text {
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #344054;
      }

      .card-sub-text {
        font-weight: 100;
        font-size: 18px;
        line-height: 28px;
        color: #667085;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      }

      .card-btn-text {
        width: 100%;
        align-items: center;
        padding: 12px 24px;
        height: 40px;
        background: #007dc6;
        border-radius: 10px;
        color: #ffffff;
      }

      .card-btn-text1 {
        margin-top: 10px;
        width: 100%;
        align-items: center;
        padding: 12px 24px;
        height: 40px;
        border-radius: 10px;
        color: #007dc6;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
      }
    }
  }

  .card-item-main2 {
    margin: 0px 0px;

    table: {
      border-spacing: 0px 4px;
    }

    .credit-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }

    .credit-amt {
      font-weight: 100;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
    }

    .credit-date {
      font-weight: 100;
      font-size: 16px;
      line-height: 24px;
      color: #98a2b3;
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    }

    .download-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #007dc6;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }

    .image-container {
      position: relative;

      .image {
        width: 100%;
        border-radius: 8px;
      }

      .back-img {
        position: absolute;
        top: 2%;

        .card-image-main-text {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: #004b77;
          padding: 25px;
        }

        .card-image-sub-text {
          font-weight: 100;
          font-size: 16px;
          line-height: 24px;
          color: #007dc6;
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
          padding: 25px;
        }

        .btn-image-main {
          background: #007dc6;
          border-radius: 10px;
          color: #ffffff;
          margin-left: 25px;
        }
      }
    }
  }
}