.stepper-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 3% 6%;
  position: relative;
  background-color: #f9fafb;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: -0.02em;
    color: #101828;
    font-family: "Gotham-Bold", "Courier New", Courier, monospace;
    margin-left: -3vw;
    padding-bottom: 3vw;
  }
  .content {
    border-left: 3.5px grey;
    border-left-style: dashed;
    padding-left: 5%;
    width: 60%;

    .step-container {
      position: relative;
      margin-bottom: 3vw;
      .title-txt {
        padding-bottom: 2%;
        font-style: normal;
        font-weight: 350;
        font-size: 24px;
        color: #027a48;
        font-family: "Gotham-Bold", "Courier New", Courier, monospace;
      }
      .title-txt-1 {
        padding-bottom: 2%;
        font-style: normal;
        font-weight: 350;
        font-size: 24px;
        color: #101828;
        font-family: "Gotham-Bold", "Courier New", Courier, monospace;
      }
      .desc {
        font-style: normal;
        font-weight: 325;
        font-size: 16px;
        line-height: 2vw;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        color: #344054;
      }
      .image-circle {
        position: absolute;
        top: -1vw;
        left: -6.9vw;
        border-radius: 50%;
        height: 5vw;
        width: 5vw;
        border: 2px solid #027a48;
        background: #f6fef9;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #027a48;
        font-size: 1.8vw;
        box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
          0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
          0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
          0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
          0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
      }
      .image-circle-1 {
        position: absolute;
        top: -1vw;
        left: -6.9vw;
        border-radius: 50%;
        height: 5vw;
        width: 5vw;
        box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
          0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
          0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
          0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
          0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
        border-radius: 1000px;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #00649e;
        font-size: 1.8vw;
      }
    }
  }
  .card-self {
    padding: 24px 20px;
    background: #ffffff;
    box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
      0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
      0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
      0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
      0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
    border-radius: 16px;
    top: 2vw;
    right: 2vw;
    position: absolute;
    width: 30vw;
    display: flex;
    flex-direction: column;

    .sub-card {
      display: flex;
      flex-direction: column;
      background: #f9fafb;
      border-radius: 6px;
      padding: 0.8vw;
      .string1 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #242a32;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
      }
      .string2 {
        font-style: normal;
        font-weight: 325;
        font-size: 14px;
        color: #475467;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
      }
    }
    .btn-ow {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      background: #007dc6;
      border-radius: 10px;
      color: #ffffff;
    }
  }
}
