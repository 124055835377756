.profile-container {
  overflow-x: hidden;
  margin: 0px !important;
  width: 100% !important;
  background: #fcfcfd;

  .sub-container {
    padding-top: 0px !important;
    position: relative;
  }

  .MuiFormControl-root {
    width: 100% !important;
  }

  .MuiInputBase-root {
    width: 100% !important;
    height: 48px !important;
  }

  .input-label {
    padding-bottom: 5px;
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
  }

  .submit-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 16px;
    height: 48px;
    background: #007dc6;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-weight: 700;
    color: #ffff;
    box-shadow: none !important;
  }

  .submit-btn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 16px;
    height: 48px;
    background: #007dc6;
    border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-weight: 700;
    color: #ffff;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #344054;
  }

  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #344054;
    margin-bottom: 15px;
  }

  .desc {
    font-style: normal;
    font-weight: 350;
    font-size: 18px;
    line-height: 28px;
    color: #007dc6;
  }

  .center-flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }

  .profile-avatar {
    width: 160px;
    height: 160px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 1000px;
    position: relative;
  }

  .icon-btn-1:hover .profile-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    position: absolute;
    width: 98px;
    height: 52px;
    left: 42px;
    top: 60px;
    visibility: visible;
  }

  .profile-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    position: absolute;
    width: 98px;
    height: 52px;
    left: 42px;
    top: 60px;
    visibility: hidden;
  }

  .nonvalid-profile-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    position: absolute;
    width: 98px;
    height: 52px;
    left: 42px;
    top: 60px;
    visibility: visible;
  }

  .generel-detail-contaner {
    padding: 24px 16px;
    gap: 32px;
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0 0 4px #e1dede !important;
    border-radius: 16px;
    margin: 20px 0px;

    .general-title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #344054;
      font-family: "Gotham-Medium" "Courier New", Courier, monospace !important;
    }

    .standards-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-standard-card {
        font-family: "Gotham-Medium" "Courier New", Courier, monospace !important;
        font-size: 18px;

        .count-div {
          background: #d2efff;
          border-radius: 5px;
          padding: 5px 8px;
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: #007dc6;
        }
      }

      .btn-container-pagination {
        display: flex;

        .aero-btn:hover {
          #left-svg-btn {
            stroke: #242a32;
          }
        }

        #left-svg-btn:hover {
          stroke: #242a32;
        }
      }
    }

    .cards-container-standards::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    .cards-container-standards::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    .cards-container-standards::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 8px;
    }

    /* Handle on hover */
    .cards-container-standards::-webkit-scrollbar-thumb:hover {
      background: white;
    }

    .cards-container-standards {
      display: flex;
      width: 50vw;
      overflow-x: scroll;
      overflow-y: hidden;

      .card-standard-profile {
        border-radius: 16px;
        border: 1px solid #f2f4f7;
        background: #fff;
        padding: 16px;
        display: flex;
        flex-direction: column;
        width: 20vw;
        margin-right: 12px;
        min-width: 220px;

        .standard-title-txt {
          color: #004b77;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          font-size: 23px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          padding-top: 16px;
          padding-bottom: 8px;
        }
        .standard-title-0 {
          color: #101828;
          font-size: 16px;
          font-style: normal;
          padding: 16px 0px 5px 0px;
          font-weight: 500;
          font-family: "Gotham", "Courier New", Courier, monospace !important;
        }
        .standard-title-1 {
          color: #004b77;
          font-size: 16px;
          font-style: normal;
          padding: 16px 0px 5px 0px;
          font-weight: 500;
          font-family: "Gotham", "Courier New", Courier, monospace !important;
        }

        .tag-container {
          border-radius: 10px;
          padding: 10px;
          background: #f9fafb;
          color: #475467;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 11px;
          width: -moz-fit-content;
          width: fit-content;
        }

        .header-card-standard-profile {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .card-profile-image {
            border-radius: 12px;
            border: 2px solid #eaecf0;
            height: 3vw;
            width: 3vw;
            margin-right: 20px;
          }

          .selected {
            margin-right: -19px;
            margin-top: -10px;
            align-self: baseline;

            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
              background-color: #0099f3 !important;
            }

            .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
              opacity: initial !important;
            }

            .MuiSwitch-track {
              width: 100%;
              height: 100%;
              z-index: -1;
              transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              border-radius: 16px;
            }

            .MuiSwitch-switchBase {
              top: 5px;
              left: 8px;
              color: #fff;
              z-index: 1;
              position: absolute;
              transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }

            .MuiSwitch-root {
              width: 72px;
              height: 49px;
              display: inline-flex;
              padding: 12px;
              z-index: 0;
              overflow: hidden;
              position: relative;
              box-sizing: border-box;
              flex-shrink: 0;
              vertical-align: middle;
            }
          }

          .unselected {
            align-self: baseline;
            margin-right: -19px;
            margin-top: -10px;

            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
              background-color: #0099f3 !important;
            }

            .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
              opacity: initial !important;
            }

            .MuiSwitch-root {
              width: 72px;
              height: 49px;
              display: inline-flex;
              padding: 12px;
              z-index: 0;
              overflow: hidden;
              position: relative;
              box-sizing: border-box;
              flex-shrink: 0;
              vertical-align: middle;
            }

            .MuiSwitch-track {
              width: 100%;
              height: 100%;
              opacity: 1;
              z-index: -1;
              border-radius: 16px;
              background-color: #f2f4f7;
            }

            .MuiSwitch-switchBase {
              top: 5px;
              left: 5px;
              color: #fff;
              z-index: 1;
              position: absolute;
              transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
          }
        }
      }
    }
  }

  .student-detail-contaner {
    padding: 24px 16px;
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 0px 5px 2px rgba(8, 12, 58, 0.04),
      0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
      0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
      0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
      0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
    border-radius: 16px;
    margin: 20px 0px;

    .general-title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #344054;
    }
  }
}

.credit-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
    0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
    0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
    0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
    0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
  position: absolute;
  right: 12px;
  top: 54px;
  border-radius: 8px;
  padding: 10px;
}

.credit-value {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #007dc6;
  padding-right: 8px;
}

.credit-name {
  width: 71px;
  height: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #007dc6;
}

.school-heading {
  position: relative;
}

.info-msg-alert {
  position: absolute;
  right: 0;
  top: 0;
  color: #dc6803;
  margin-right: 10px;
}

.info-msg-alert .MuiAlert-message {
  color: #dc6803 !important;
}

.general-profile-form {
  width: 100%;
}

.error-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px;
  background: #fef3f2;
  border-radius: 10px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  .title {
    font-family: "Gotham", "Courier New", Courier, monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #d92d20;
    padding-bottom: 12px;
    align-self: flex-start;
  }

  .error-list {
    padding: 0px;
    margin: 0px;
    align-self: flex-start;
    padding-left: 23px;

    .error {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #d92d20;
    }
  }
}
