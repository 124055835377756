.training-details {
  padding: 0px !important;
  margin: 0px !important;

  /* width */
  .main::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  .main::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  .main::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 8px;
  }

  /* Handle on hover */
  .main::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  /* width */
  .main1::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  .main1::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  .main1::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 8px;
  }

  /* Handle on hover */
  .main1::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  /* width */
  .sidebar::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  .sidebar::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  /* Handle */
  .sidebar::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 8px;
  }

  /* Handle on hover */
  .sidebar::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  .main {
    margin: 0px !important;
    padding: 0px !important;
    height: auto;
    background: #fcfcfd;
    height: 100vh;
    overflow-y: scroll;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      padding-top: 30px;
      background-color: #ffff;

      .titles {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
        width: 80%;
      }

      .finish-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 25px;
        gap: 16px;
        background: #007dc6;
        border-radius: 10px;
        color: #ffffff;
      }

      .completed-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 25px;
        grid-gap: 16px;
        gap: 16px;
        background: #ecfdf3;
        border: 1px solid #039855;
        border-radius: 10px;
        color: #039855;
      }
    }

    .content {
      margin: 27px auto;
      background: #fcfcfd;

      .btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .btn1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }

      .course-content-container {
        display:flex;
        flex-direction: column;
        min-height: 75vh;
        background: #fcfcfd;
        color: #242A32;
        font-size: 17px;
        font-style: normal;
        font-weight: 325;
        line-height: 28px;
        gap:25px;
        /* 155.556% */
        p {
          display:flex;
          flex-direction: column;
          color: #000000;
          font-family:"Gotham-Light", "Courier New", Courier, monospace !important ;
          gap:25px;
        }
        a{
          background-color: white;
          padding: 12px;
          border-radius: 10px;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        }
        h4 {
          color: #000000;
          font-family:"Gotham-Medium1", "Courier New", Courier, monospace !important ;
          font-weight: 700;
        }
        img {
          border-radius: 10px;
          height: 400px;
          width: 90%;
        }
      }

      .change-btn1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 16px;
        width: 49%;
        height: 49px;
        background: #ffffff;
        border-radius: 10px;
        color: #007dc6;
        font-family: "Gotham-medium", "Courier New", Courier, monospace !important;
      }

      .change-btn2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 16px;
        width: 49%;
        height: 49px;
        background: #007dc6;
        border-radius: 10px;
        color: white;
        font-family: "Gotham-medium", "Courier New", Courier, monospace !important;
      }

      .course-content {
        padding-bottom: 25px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        color: inherit;
      }
    }
  }

  .main1 {
    margin: 0px !important;
    padding: 0px !important;
    display: block;
    height: 100vh;
    overflow-y: scroll;

    .header-one {
      position: relative;
      height: 35vh;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .back-image {
        width: 100%;
        height: 100%;
      }

      .cover-div {
        background-color: black;
        opacity: 0.4;
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;
      }

      .title {
        position: absolute;
        padding-left: 3%;
        font-style: normal;
        font-weight: 700;
        font-size: 2.5vw;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #f9f9f9;
        font-family: "Gotham-Bold", "Courier New", Courier, monospace;
      }

      .componet-abs {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 94%;
        left: 3%;
        bottom: -1.8vw;
        padding: 4px;
        background: #ffffff;
        border: 1px solid #f2f4f7;
        border-radius: 10px;

        .start-tech-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          background: #007dc6;
          border-radius: 10px;
          color: #ffffff;
          font-weight: bold;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;
        }

        .pg-sk1 {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          font-weight: 300;
        }

        .btn-container {
          display: flex;
          width: 52%;
          justify-content: flex-end;
          padding-right: 0%;

          .cmn-btn {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            background-color: #ffffff;
            color: #007dc6;
            margin-right: 12px;
            text-decoration: none;

            .project-card-text {
              color: #007dc6;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            }
          }

          .cmn-btn1 {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            background-color: #e8f6fe;
            color: #00649e;
            border-radius: 8px;
            padding: 12px 14px !important;
            margin-left: 12px !important;
          }

          .pg-sk2 {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            font-weight: 300;
          }
        }
      }
    }

    .content {
      background-color: #fcfcfd;
      padding: 3vw 3% 15px 3%;
      display: flex;

      .divider-grey {
        width: 2px;
        margin: -2vw 12px;
        background-color: #f2f4f7;
      }

      .left {
        width: 70%;
        display: flex;
        flex-direction: column;

        .title1 {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          font-style: normal;
          font-weight: 350;
          font-size: 18px;
          color: #242a32;
          padding-bottom: 1.3vw;
        }
        .title1-p {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          font-style: normal;
          font-weight: 350;
          font-size: 18px;
          color: #242a32;
        }

        .content-card {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          background: #ffffff;
          border-radius: 8px;
          min-height: 52vh;
          // box-shadow: 0 0 10px #e1dede !important;
          border: 2px solid #f2f4f7;

          .tabs-header {
            display: flex;
            align-items: flex-start;
            padding: 0px;
            width: 100%;
            justify-content: space-evenly;
            background: #ffffff;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            margin-bottom: 12px;

            .tab-title-container {
              width: 33.33%;
              padding: 1%;
              border-width: 1px 1px 0px 1px;
              border-style: solid;
              border-color: #f2f4f7;
            }

            .tab-title-container-default {
              width: 33.33%;
              padding: 1%;

              .txt-title {
                font-family: "Gotham-Light", "Courier New", Courier, monospace;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #475467;
              }
            }
          }

          .tabs-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px 20px 20px 20px;
            width: 100%;

            .tab-con-title {
              font-family: "Gotham-Light", "Courier New", Courier, monospace;
              font-weight: bold;
              font-size: 14px;
              color: #667085;
              line-height: 40px;
            }

            .tab-con-des {
              font-family: "Gotham-Light", "Courier New", Courier, monospace;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #242a32;
            }

            .tab-con-des-1 {
              font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
              font-style: normal;
              font-weight: 900;
              font-size: 16px;
              line-height: 24px;
              color: #242a32;
            }

            .card-container {
              margin: 0px;
              padding: 0px;
              width: 100% !important;
              margin-top: 2vw;

              .card-grid {
                display: flex;
                width: 100%;
                align-items: stretch;
                justify-content: stretch;
                margin-top: -0px;

                .card {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  border: 1px solid #f2f4f7;
                  border-radius: 8px;
                  background: #fcfcfd;
                  padding: 20px;
                  width: 100%;

                  .txt-title {
                    font-family: "Gotham-Medium1", "Courier New", Courier,
                      monospace;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #242a32;
                    padding: 10px 0px;
                  }

                  .txt-des {
                    font-family: "Gotham-Light", "Courier New", Courier,
                      monospace;
                    font-weight: bold;
                    font-size: 14px;
                    color: #667085;
                  }
                }
              }
            }
          }
        }
        .content-card-p {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          background: #ffffff;
          border-radius: 8px;
          min-height: 52vh;
          // box-shadow: 0 0 10px #e1dede !important;
          // border: 2px solid #f2f4f7;

          .tabs-header {
            display: flex;
            align-items: flex-start;
            padding: 0px;
            width: 100%;
            justify-content: space-evenly;
            background: #ffffff;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            margin-bottom: 12px;

            .tab-title-container {
              width: 33.33%;
              padding: 1%;
              border-width: 1px 1px 0px 1px;
              border-style: solid;
              border-color: #f2f4f7;
            }

            .tab-title-container-default {
              width: 33.33%;
              padding: 1%;

              .txt-title {
                font-family: "Gotham-Light", "Courier New", Courier, monospace;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #475467;
              }
            }
          }

          .tabs-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            .tab-con-title {
              font-family: "Gotham-Light", "Courier New", Courier, monospace;
              font-weight: bold;
              font-size: 14px;
              color: #667085;
              line-height: 40px;
            }

            .tab-con-des {
              font-family: "Gotham-Light", "Courier New", Courier, monospace;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #242a32;
            }

            .tab-con-des-1 {
              font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
              font-style: normal;
              font-weight: 900;
              font-size: 16px;
              line-height: 24px;
              color: #242a32;
            }

            .card-container {
              margin: 0px;
              padding: 0px;
              width: 100% !important;
              margin-top: 2vw;

              .card-grid {
                display: flex;
                width: 100%;
                align-items: stretch;
                justify-content: stretch;
                margin-top: -0px;

                .card {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  border: 1px solid #f2f4f7;
                  border-radius: 8px;
                  background: #fcfcfd;
                  padding: 20px;
                  width: 100%;

                  .txt-title {
                    font-family: "Gotham-Medium1", "Courier New", Courier,
                      monospace;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #242a32;
                    padding: 10px 0px;
                  }

                  .txt-des {
                    font-family: "Gotham-Light", "Courier New", Courier,
                      monospace;
                    font-weight: bold;
                    font-size: 14px;
                    color: #667085;
                  }
                }
              }
            }
          }
        }
      }

      .right {
        width: 30%;
        display: flex;
        flex-direction: column;

        .title1 {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          font-style: normal;
          font-weight: 350;
          font-size: 18px;
          color: #242a32;
          margin-bottom: 14px;
        }

        .card {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;

          .card-back-image {
            width: 100%;
          }

          .card-content {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0px 20px;

            .card-details {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .title-card {
                font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 34px;
                color: #007dc6;
              }

              .desc-card {
                font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #004b77;
              }
            }

            .icon-card-one {
              height: 80%;
            }
          }
        }

        .card0 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 24px 16px;
          background: #ffffff;
          border: 1px solid #f2f4f7;
          border-radius: 10px;
          margin-bottom: 10px;

          .title-card {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            color: #007dc6;
            line-height: 0px;
          }

          .desc-card {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #004b77;
            line-height: 60px;
          }

          .image-card0 {
            height: 140px;
            align-self: center;
            border-radius: 10px;
            width: 100%;
            border: 1px solid #f2f4f7;
          }
        }

        .card3 {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;

          .card-back-image {
            width: 100%;
          }

          .card-content {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 70%;
            padding: 0px 20px;

            .card-details {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .title-card {
                font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 34px;
                color: #007dc6;
              }

              .desc-card {
                font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #004b77;
              }
            }
          }
        }
      }
    }
  }

  .sidebar {
    margin: 0px !important;
    padding: 0px !important;
    background: white !important;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    box-shadow: 0 0 2px #e1dede;

    .sidebar1 {
      .accordian-container-1 {
        height: 62vh;
        overflow-y: auto;
        overflow-x: hidden;
        width: inherit;

        .MuiAccordion-root:after {
          left: 0;
          right: 0;
          height: 1px;
          content: "";
          opacity: 1;
          position: absolute;
          transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          background-color: rgba(0, 0, 0, 0.12);
        }

        .accordian-container {
          .MuiAccordion-root.Mui-expanded:before {
            opacity: 1;
          }
        }
      }

      .header {
        position: relative;
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .header-image {
          width: inherit;
        }

        .header-title {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          position: absolute;
          width: 100%;
          top: 55%;

          .progress-bar-title {
            display: flex;

            .title-with-progress {
              display: flex;
              flex-direction: column;
              align-self: center;
              justify-content: center;
              padding-right: 16px;
            }

            .value-progress {
              align-self: flex-end;
              margin-bottom: -2px;
            }
          }

          .title {
            font-weight: 700;
            font-size: 1.5vw;
            line-height: 1.5vw;
            color: #00649e;
            padding-bottom: 10px;
            text-align: center;
            font-family: "Gotham", "Courier New", Courier, monospace;
          }

          .MuiTypography-root {
            padding-right: 24px;
            color: #00649e;
          }

          .MuiLinearProgress-colorPrimary {
            background-color: white;
            height: 6px;
            border-radius: 100px;
          }

          .MuiLinearProgress-barColorPrimary {
            background-color: #69d584;
          }
        }

        .header-title1 {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          position: absolute;
          width: 100%;
          top: 62%;
          padding: 0px 12px;

          .progress-bar-title {
            display: flex;

            .title-with-progress {
              display: flex;
              flex-direction: column;
              align-self: center;
              justify-content: center;
              padding-right: 16px;
            }

            .value-progress {
              align-self: flex-end;
              margin-bottom: -2px;
            }
          }

          .title {
            font-weight: 700;
            font-size: 1.5vw;
            line-height: 1.5vw;
            color: #00649e;
            padding-bottom: 10px;
            text-align: center;
            font-family: "Gotham", "Courier New", Courier, monospace;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 300px;
          }

          .MuiTypography-root {
            padding-right: 24px;
            color: #00649e;
          }

          .MuiLinearProgress-colorPrimary {
            background-color: white;
            height: 6px;
            border-radius: 100px;
            width: 241px;
          }

          .MuiLinearProgress-barColorPrimary {
            background-color: #12b76a;
          }
        }
      }

      .divider {
        width: 100%;
      }

      .accordian-container {
        background-color: inherit;
        box-shadow: none;
        border-radius: 0px !important;
        width: inherit;

        .MuiAccordionSummary-root {
          padding: 5px 0px !important;
        }

        .title-project-overview {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #344054;
          padding-left: 9px;
          font-family: "Gotham", "Courier New", Courier, monospace;
        }

        .subTextBox {
          display: flex;
          gap: 30;
        }
        
        .title-project {
          cursor: pointer;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #475467;
          padding-left: 9px;
          font-family: "Gotham", "Courier New", Courier, monospace;
        }

        .accordion-details {
          display: flex;
          flex-direction: column;
          padding: 0px 10px !important;
          width: 100%;
          justify-content: center;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;
          margin-bottom: 20;

          .Overview-txt {
            font-weight: 100;
            font-size: 14px;
            line-height: 20px;
            color: #475467;
            background: #fcfcfd;
            border-radius: 4px;
            margin-bottom: 5px;
            padding: 8px;
            width: 89%;
          }
        }
      }

      .MuiPaper-elevation1 {
        box-shadow: none !important;
        background: inherit;
        border-radius: 0px !important;
        margin-top: 0px;
      }

      .Mui-expanded {
        margin: 0px !important;

        .MuiCollapse-entered {
          margin-bottom: 10px !important;
        }
      }

      .title-steps {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #344054;
        font-family: "Gotham", "Courier New", Courier, monospace;
      }

      .pl-16 {
        padding-left: 25px;
      }

      .steps-container {
        padding-left: 35px;
        padding-top: 18px;

        .course-step {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px;
          cursor: pointer;

          .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #475467;
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          }
        }

        .course-step-selected {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px;
          background-color: #e8f6fe;
          border-radius: 4px;

          .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #475467;
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          }
        }
      }
    }
  }

  .header-preview-mode {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007dc6;
    padding: 20px 30px;
    height: 10vw;
    width: 100%;

    .left-container-txt {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title-txt0 {
        color: #ffffff;
        align-self: flex-start;
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: -0.72px;
        font-family: "Gotham-Bold", "Courier New", Courier, monospace;
      }

      .desc-txt0 {
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-size: 21px;
        font-weight: 800;
        line-height: 42px;
        color: #fff;
      }
    }

    .order-btx-classbox {
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      display: flex;
      background-color: #e8f6fe;
      color: #00649e;
      padding: 12px 16px !important;
      font-size: 16px !important;
    }
  }
}

.footer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  height: 108px;
  border: 1px solid #f2f4f7;
  border-radius: 10px;
  width: 85%;
  position: absolute;
  bottom: 20px;
  left: 9px;
  margin: 0px 14px;

  .first {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 100%;
    background: #f9fafb;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #344054;
    }
  }

  .second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 48px;
    width: 100%;
    background: #e8f6fe;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #007dc6;
    }
  }

  .templdkd {
    align-self: center;
    padding-left: 12px;
    width: 287px;
    margin-right: 25px;
  }
}

.footer-card01 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  border: 1px solid #f2f4f7;
  border-radius: 10px;
  width: 85%;
  position: absolute;
  bottom: 20px;
  left: 9px;
  margin: 0px 14px;

  .second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    height: 48px;
    width: 100%;
    background: #e8f6fe;
    border-radius: 10px;
    cursor: pointer;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #007dc6;
    }
  }

  .templdkd {
    align-self: center;
    padding-left: 12px;
    width: 287px;
    margin-right: 25px;
  }
}

.icon-sidebar-001 {
  position: absolute;
  left: 44%;
  height: 35px;
  top: 6%;
}

.accordian-container-3 {
  padding: 0px;
  border-radius: 0px;

  .MuiAccordion-rounded {
    border-bottom: 1px solid #eaecf0;
  }

  .sta-tab-content {
    margin: 0px;
    // background-color: #f2f4f7;
    padding: 24px;
    padding-top: 40px;

    .standard-one-item {
      .tips-tool-title {
        color: #667085;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding-top: 12px;
        padding-bottom: 10px;
      }

      .cont-padd {
        padding: 10px 0px 18px 4px;

        .standard-title-x {
          color: #667085;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          padding-bottom: 5px;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }
      }

      .content-of-standards-tab {}
    }
  }

  .tab-content-btn-x {
    margin: 0px;
    position: absolute;
    width: 32vw;
    left: 20vw;
    top: -20px;
    border-radius: 8px;
    box-shadow: 0 0 1px #e1dede;

    .tabs {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px;
      background: #ffff;
      border-radius: 10px;
      margin: 0px !important;

      .MuiGrid-item {
        padding: 2px;
      }

      .pointer-cursor-on-0011 {
        cursor: pointer;
      }

      .activebtn {
        background: #007dc6;
        padding: 15px 45px;
        border-radius: 8px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .nonactivebtn {
        background: #ffff;
        padding: 15px 45px;
        border-radius: 8px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #475467;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .nonactivebtn:hover {
        background: #007dc6;
        padding: 15px 45px;
        border-radius: 8px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
      }
    }

    .compare {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin: 0px !important;

      .activebtn {
        background: #007dc6;
        padding: 15px;
        border-radius: 8px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .nonactivebtn {
        background: white;
        padding: 15px;
        border-radius: 8px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 325;
        font-size: 14px;
        color: #475467;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      .nonactivebtn:hover {
        background: #007dc6;
        padding: 15px;
        border-radius: 8px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
      }
    }

    .fields {
      margin: 0px !important;

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
        /*For FireFox*/

        &::-webkit-inner-spin-button {
          /*For Webkits like Chrome and Safari*/
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  .accordian-container {
    background-color: inherit;
    box-shadow: none;
    border-radius: 0px !important;
    width: inherit;

    .MuiAccordionSummary-root {
      box-shadow: none;
      padding: 0px 20px !important;
    }

    .MuiPaper-elevation1 {
      box-shadow: none !important;
      border-radius: 0px !important;
    }

    .remove-before::before {
      top: 0px;
      left: 0;
      right: 0;
      height: 1px;
      content: "";
      opacity: 0;
      position: absolute;
      background-color: #fff;
    }

    .title-project-overview {
      color: #667085;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace;

      .profile-image {
        width: 3vw;
        height: 3vw;
        border-radius: 12px;
        border: 2px solid #eaecf0;
        background: lightgray;
        margin-right: 10px;
      }
    }

    .accordion-details {
      display: flex;
      flex-direction: column;
      padding: 0px !important;
      width: 100%;
      padding-top: 10px !important;
      justify-content: center;
      font-family: "Gotham-Light", "Courier New", Courier, monospace;

      .Overview-txt {
        font-weight: 100;
        font-size: 14px;
        line-height: 20px;
        color: #475467;
        background: #fcfcfd;
        border-radius: 4px;
        margin-bottom: 5px;
        padding: 8px;
        width: 89%;
      }

      .details-card {
        border-radius: 10px;
        border: 1px solid #eaecf0;
        background: #fcfcfd;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        .left-content-container {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .standard-title-txt {
            color: #007dc6;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            padding-bottom: 5px;
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          }

          .standard-description-txt {
            color: #344054;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
          }
        }
      }
    }
  }

  .MuiPaper-elevation1 {}

  .Mui-expanded {
    margin: 0px !important;
  }

  .title-steps {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    font-family: "Gotham", "Courier New", Courier, monospace;
  }

  .pl-16 {
    padding-left: 25px;
  }

  .steps-container {
    padding-left: 35px;
    padding-top: 18px;
    position: relative;

    .course-step {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #475467;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      }
    }

    .course-step-selected {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      background-color: #e8f6fe;
      border-radius: 4px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #475467;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      }
    }
  }
}

.project-overview-button{
  width: 75% !important;
}