.table-component-container {
  .MuiPopover-paper {
    box-shadow: 0 0 10px #e1dede !important;
  }
}
.MuiPaper-elevation8 {
  box-shadow: none !important;
  box-shadow: 0 0 10px #e1dede !important;
  border-radius: 10px !important;
}
