.common-header {
  padding: 0px;
  margin: 0px !important;
  width: fit-content !important;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #101828;
  }
}
