.complete-taining-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 60px;
    background-color: #007dc6;
    height: 10vh;
  }
  
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #f9fafb;
    .card-success-of-classbox{
      width: 40% !important;
    }
    .card {
      position: relative;
      border-radius: 16px;
      background: #fff;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0.9274210333824158px 2.7479140758514404px 0px rgba(8, 12, 58, 0.01),
        0px 2.34551739692688px 6.949680805206299px 0px rgba(8, 12, 58, 0.02),
        0px 4.784635543823242px 14.176697731018066px 0px rgba(8, 12, 58, 0.02),
        0px 9.855443954467773px 29.201316833496094px 0px rgba(8, 12, 58, 0.03),
        0px 27px 80px 0px rgba(8, 12, 58, 0.04);

      .img1-of-trainning {
        position: absolute;
        top: -43px;
        align-self: center;
        display: flex;
        justify-content: center;
      }

      .header {
        background-color: #ecfdf3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 75px 100px;

        .title {
          color: #05603a;
          font-size: 24px;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
        }

        .title-of-header-proj {
          margin-top: 15px;
        }
        .desc-text-center{
          text-align: center;
        }
        .desc {
          color: #027a48;
          font-size: 14px;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;
          font-style: normal;
          font-weight: 325;
          line-height: 20px;
        }

        .bold-title-0059rt {
          font-weight: 800 !important;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        padding: 24px;

        .cardone {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          background-color: #f9fafb;
          border-radius: 6px;

          .title {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            color: #242a32;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
          }

          .sub-title {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            color: #242a32;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
          }
          .desc-text-center{
            text-align: center;
          }
          .desc {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            color: #475467;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
          .vertical-div-span{
            margin-top: 1%;
          }
        }

        .share-txt {
          color: #667085;
          font-size: 12px;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          font-style: normal;
          font-weight: 350;
          line-height: 18px;
          align-self: flex-start;
          margin: 10px 0px;
        }

        .btn-container {
          display: flex;
          justify-content: space-between;

          .btn-social {
            width: 49%;
            border-radius: 8px;
            background: #d2efff;
            color: #00649e;
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            height: 45px;
            margin-bottom: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
          }
        }

        .dashboard-btn {
          border-radius: 10px;
          background: #007dc6;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 350;
          line-height: 24px;
          height: 48px;
        }
      }
    }
  }
}