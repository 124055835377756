.lead-dashboard {
  background-color: hsl(240, 20%, 99%) !important;

  .main {
    .common-header {
      padding: 0px;
      margin: 0px !important;
      width: fit-content !important;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
        color: #101828;
      }

      .name {
        color: #007dc6;
      }
    }

    .card0 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 24px 20px;
      height: 118px;
      background: #007dc6;
      border-radius: 10px;
      margin-bottom: 10px;
      margin-left: 22px;
      margin-right: 22px;
      box-shadow: 0 0 3px;

      .left {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }

      .right {
        display: flex;
        justify-content: flex-end;
      }

      .btn-plus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        height: 48px;
        background: #e8f6fe;
        border-radius: 10px;
        min-width: 170px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        box-shadow: unset !important;
      }

      .number {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: #ffffff;
      }

      .desc {
        font-size: 16px;
        line-height: 24px;
        color: #d2efff;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        font-weight: bold;
      }

      .btn-text {
        color: #007dc6 !important;
        text-transform: none;
        display: flex;
        align-items: center;
      }
    }

    .card1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 24px 20px;
      height: 118px;
      background: #ffffff;
      border-radius: 10px;
      margin-left: 22px;
      margin-right: 22px;
      box-shadow: 0 0 4px #e1dede;

      .left {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }

      .right {
        display: flex;
        justify-content: flex-end;
      }

      .btn-plus {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        height: 48px;
        background: #e8f6fe;
        border-radius: 10px;
      }

      .number {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: #242a32;
      }

      .desc {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #475467;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      }

      .btn-text {
        color: #007dc6 !important;
      }
    }

    .sub-header {
      margin-left: 22px;
      margin-right: 22px;
      padding: 30px 0px;

      .desc {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #344054;
      }

      .right {
        display: flex;
        justify-content: flex-end;

        .btn-right-aero {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px;
          gap: 16px;
          width: 107px;
          height: 24px;
        }

        .btn-text {
          color: #007dc6;
          font-weight: 600;
        }
      }
    }

    .card-container {
      margin-left: 10px;
      margin-right: 10px;

      .MuiTypography-body2 {
        font-size: 18px !important;
        font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
        font-weight: 600 !important;
        letter-spacing: 0.01071em !important;
      }

      .teacher-card-header-desc {
        font-size: 16px !important;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        font-weight: bold;
        letter-spacing: 0.01071em !important;
        color: #007dc6;
      }
    }
  }

  .bg-card {
    background-color: #fcfcfd;
  }

  .card-header {
    height: 71px;
  }

  .card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 60px;
    background-color: #fcfcfd;

    .padding-left {
      padding-left: 12px;
    }

    .item {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 50%;

      .number {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 42px;
        color: #004b77;
        font-family: "Gotham-Bold", "Courier New", Courier, monospace !important;
      }

      .desc {
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 18px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        color: #667085;
      }
    }
  }

  .sidebar {
    padding-left: 20px;
    border-left: 1px solid #eaecf0;

    .chart-title-container {
      padding-top: 12px;
      padding-bottom: 25px;
    }

    .chart-title-container-1 {
      padding-top: 24px;
      padding-bottom: 25px;
    }

    .chart-title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #344054;
    }

    .chart-container {
      width: 100%;
      height: 260px;
      background: #ffffff;
      border: 1px solid #eaecf0;
      border-radius: 10px;
      box-shadow: 0 0 4px #e1dede;
    }

    .MuiListItem-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .MuiListItem-secondaryAction {
      padding-right: 0px !important;
      width: 68% !important;
    }

    .MuiListItemSecondaryAction-root {}

    .transaction-list {
      padding: 0px;
      background: #ffffff;
      border: 1px solid #eaecf0;
      border-radius: 10px;
      box-shadow: 0 0 4px #e1dede;
      overflow: auto;
      height: 600px;

      /* width */
      .infinite-scroll-component::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      .infinite-scroll-component::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      /* Handle */
      .infinite-scroll-component::-webkit-scrollbar-thumb {
        background: #eaecf0;
        border-radius: 8px;
      }

      /* Handle on hover */
      .infinite-scroll-component::-webkit-scrollbar-thumb:hover {
        background: #eaecf0;
      }

      .list-item-container {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        position: relative;
        box-sizing: border-box;
        text-align: left;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: space-between;
        text-decoration: none;

        .left {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          width: 70%;
        }

        .title {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #344054;
        }

        .sec-txt {
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #007dc6;
        }
      }
    }
  }

  .video-card {
    padding: 0px !important;
  }

  .m-0 {
    margin: 0px !important;
  }
}

.MuiDialog-paperWidthSm {
  width: 600px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
    0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
    0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
    0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
    0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
  border-radius: 16px;

  .video-div {
    padding: 0px !important;
  }

  .video-player {
    border-radius: 16px;

    iframe {
      display: block !important;
    }
  }

  .MuiDialogContent-root {
    flex: none !important;
    position: relative;
  }
}

.MuiPaper-rounded {
  border-radius: 16px !important;
}

.video-title-txt {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #242a32;
}

.video-desc-txt {
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.school-name-video {
  font-family: "Gotham-Bold", "Courier New", Courier, monospace;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.pad-34-20 {
  padding: 34px 20px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-150 {
  padding-bottom: 22px;
}

.video-modal-actions {
  display: flex;
  justify-content: space-between;

  .left-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    height: 48px;
    border-radius: 10px;
    color: #98a2b3;
  }

  .right-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    height: 48px;
    background: #007dc6;
    border-radius: 10px;
    color: #ffffff;
  }

  .right-btn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    height: 48px;
    background: #007dc6 !important;
    border-radius: 10px;
    color: #ffffff;
  }
}

.playbutton {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  .play-btn {
    padding: 10px 20px;
    height: 52px;
    background: #ffffff;
    box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
      0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
      0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
      0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
      0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
    border-radius: 1000px;
    align-self: center;
  }
}

.red {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  height: 32px;
  background: #fffbfa;
  color: #b42318;
  border-radius: 4px;
  margin: 4px 0px;
}

.green {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  height: 32px;
  color: #027a48;
  border-radius: 4px;
  margin: 4px 0px;
}

.green:hover {
  cursor: pointer;
}

.p-l5 {
  padding-left: 5px;
}