.dialog-container {
  .title-text {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #242a32;
    padding-bottom: 0px;
  }
  .content-text {
    font-family: "Gotham-light", "Courier New", Courier, monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }
  .modal-title-bold {
    .MuiTypography-root {
      font-family: "Gotham", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #242a32;
    }
  }

  .warn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 16px;
    border-radius: 10px;
    margin: 15px 0px;
    .warn-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color:#344054;
      font-family: "Gotham-Medium", "Courier New", Courier, monospace;
    }
  }
  .ok-btn-blue {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 16px;
    background: #007dc6;
    border-radius: 10px;
    color: #ffffff;
    cursor: pointer;
  }
  .ok-btn-red{
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    gap: 16px;
    color: #ffffff;
    background: #D92D20;
    cursor: pointer;
  }
  .cancle-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 16px;
    color:#475467;
    border-radius: 10px;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .btn-container-confirm-dialog {
    padding-bottom: 24px;
    padding-right: 24px;
  }
  .role-text {
    color: #475467;
    font-family: Gotham;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    font-weight: 300;
  }
  .content-text .bold-content-text {
    font-family: "Gotham", "Courier New", Courier, monospace !important;
  }
  .title-cross-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    cursor: pointer;
  }
}
