.myschool-container {
  
  overflow-x: hidden;
  margin: 0px !important;
  width: 100% !important;
  background: #fcfcfd;
  display: flex;
  padding: 40px 24px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;

  .school-title-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    border-radius: 16px;
    width: 100%;
    justify-content: space-between;

    .upload-section {
      display: flex;
      align-items: center;
      gap: 16px;
      width: fit-content;
      &:hover{
        .upload-photo-btn {
          visibility:visible;
        }
        
      }

      .upload-photo-btn {
        visibility: hidden;
      
      }
      

      .upload-icon-and-text {
        display: flex;
        width: 98px;
        height: 52px;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        justify-content: center;

        .upload-info-text {
          color: #fff;

          font-family: Gotham;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .school-title {
        color: #344054;

        font-family: Gotham;
        font-size: 36px;
        font-style: normal;
        font-weight: 350;
        line-height: 44px;

        letter-spacing: -0.72px;
      }

      .school-subtitle {
        color: #007dc6;

        font-family: "Gotham-Light" !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 350;
        line-height: 28px;
      }
      .school-title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
      }
    }
    .credits {
      display: flex;
      padding: 10px;
      align-items: baseline;
      gap: 8px;
      border-radius: 8px;
      background: #fff;
      width: fit-content;

      box-shadow: 0px 27px 80px 0px rgba(8, 12, 58, 0.04),
        0px 9.855px 29.201px 0px rgba(8, 12, 58, 0.03),
        0px 4.785px 14.177px 0px rgba(8, 12, 58, 0.02),
        0px 2.346px 6.95px 0px rgba(8, 12, 58, 0.02),
        0px 0.927px 2.748px 0px rgba(8, 12, 58, 0.01);

      .credits-value {
        color: #007dc6;

        font-family: Gotham;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
    }
  }

  .school-address-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 16px;
    padding: 24px 16px;
    align-self: stretch;
    row-gap: 10px;

    .address-details-section {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }
  .standard-sets-cards {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    .standard-sets-card {
      display: flex;
      min-width: 250px;
      padding: 16px;
      align-items: center;
      gap: 24px;
      flex-direction: column;
      img {
        width: 48;
        height: 48;
      }
    }
    .card-border-white {
      border-radius: 16px;
      border: 1px solid #f2f4f7;
      background: #fff;
    }
    .card-border-grey {
      border-radius: 16px;
      border: 1px solid #f2f4f7;
      background: #f9fafb;
    }
  }
  .row-spacebetween {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .text-16 {
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
  }
  .text-12 {
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
  }
  .text-20 {
    font-size: 20px;
    font-style: normal;
    line-height: 30px;
  }
  .text-24 {
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
  }

  .weight-325 {
    color: #475467;
    font-weight: 325;
    font-family: "Gotham-Light" !important;
  }

  .weight-350 {
    color: #101828;
    font-weight: 350;
  }
  .grey-500 {
    color: #667085 !important;
  }
  .primary-700 {
    color: #004b77 !important;
  }
  .chip-background-light {
    border-radius: 6px;
    background: #f9fafb;
  }
  .chip-background-dark {
    border-radius: 6px;
    background: #eaecf0;
  }
  .numberCard {
    display: flex;
    width: 34px;
    height: 34px;
    padding: 5px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #d2efff;
  }
  .flex {
    display: flex;
    align-items: center;
    gap: inherit;
  }
  .border-with-white-bg {
    border-radius: 16px;
    background: #fff;
  }
  .border-with-grey-bg {
    border-radius: 16px;
    border: 1px solid #f2f4f7;
    background: #f9fafb;
  }
}
