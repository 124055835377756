.container-comfirmed {
  
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;

  position: absolute;

  left: calc(50% - 600px / 2);
  top: calc(50% - 406px / 2 + 12px);
  background: #ffffff;
  box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
    0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
    0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
    0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
    0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
  border-radius: 16px;

  .img {
    position: absolute;
    top: -39px;
    align-self: center;
    display: flex;
    justify-content: center;
  }

  .top-div {
    background: #ecfdf3;
    text-align: center;
    padding: 37px 25px 25px 25px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #05603a;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
    }

    .description {
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      font-weight: 100;
      font-size: 14px;
      line-height: 20px;
      color: #027a48;

      .des-bold {
        font-family: "Gotham", "Courier New", Courier, monospace;

      }
    }
  }

  .bottom-div {
    // display: flex;
    width: 100%;
    padding: 25px;

    .sub-div {
      padding: 10px 16px;
      gap: 10px;
      background: #f9fafb;
      border-radius: 6px;

      .amt {
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #242a32;
      }

      .credits {
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #242a32;
      }

      .total-credits {
        // font-weight: 100;
        // font-size: 14px;
        // line-height: 20px;
        // font-family: "Gotham-Light", "Courier New", Courier, monospace;
        color: #475467;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: #242a32;
      }
    }

    .right-btn {
      width: 100%;
      margin-top: 35px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      height: 48px;
      background: #007dc6;
      border-radius: 10px;
      color: #ffffff;
      gap: 16px;
      grid-gap: 16px;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }
  }
}