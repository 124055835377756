.main-container {
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  height: auto !important;
  margin: 0px !important;

  div {
    overflow: unset !important;
  }

  .min-h-100vh {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header {
    background-color: #007dc6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0px 20px 54px;
  }

  .main-left {
    padding-left: 0px !important;

    .back-btn {
      padding: 20px 0px;
      display: flex;
      align-items: center;
    }

    .pointer {
      // width: 24px;
      height: 24px;
      color: #101828;
      padding-right: 30px;
    }

    .header-text {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #101828;
    }

    .card-main-div {
      border-radius: 10px;
      border: 1px solid #eaecf0;
      box-shadow: 0 0 4px #e1dede;
      background-color: white;

      .toggle-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 25px;
        background-color: #344055;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        .sub-text {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #ffffff;
        }

        .toggle-back {
          background-color: white;
          width: 400px;
          padding: 4px;
          border-radius: 10px;

          .toggle-btn {
            color: #475467;
            padding: 0px !important;
            padding: 5px !important;
            width: 50%;
            border: white;
            text-transform: none;
          }

          .toggle-btn:hover {
            color: #475467;
            padding: 0px !important;
            padding: 5px !important;
            width: 50%;
            border: white;
          }

          .toggle-btn-color {
            padding: 5px !important;
            width: 50%;
            background: #007dc6;
            color: white;
            border: white;
            border-radius: 10px;
            text-transform: none;
          }

          .toggle-btn-color:hover {
            padding: 5px !important;
            width: 50%;
            background: #007dc6;
            color: white;
            border: white;
            border-radius: 10px;
          }
        }
      }

      .center-div {
        padding: 30px 20px 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        flex-wrap: wrap;

        .center-left {
          width: 46%;

          .heading-div {
            display: flex;
            align-items: center;
            padding-bottom: 7px;

            .cal-img {
              padding-right: 10px;
            }
          }

          .padl-26 {
            padding-left: 26px;
          }

          .order-text {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }

          .order-text1 {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }
        }

        .increment-error {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f9f9f9;
          border-radius: 8px;
          border: 1px solid #d92d20;
          width: 155px;
          height: 45px;
          .increment-text {
            padding: 6px 8px;
          }

          .increment-btn {
            color: #007dc6;
            font-size: 24px;
          }
        }

        .increment {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f9f9f9;
          border-radius: 8px;
          border: 1px solid #f9f9f9;
          width: 155px;
          height: 45px;

          .increment-text {
            padding: 6px 8px;
          }

          .increment-btn {
            color: #007dc6;
            font-size: 24px;
          }
        }

        .card-main-div {
          border-radius: 10px;
          border: 1px solid #eaecf0;
          box-shadow: 0 0 4px #e1dede;
          background-color: white;

          .toggle-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 25px;
            background-color: #344055;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;

            .sub-text {
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
              color: #ffffff;
            }

            .purchase-btn {
              color: white !important;
              background-color: #485468 !important;
              border-radius: 50px !important;
              padding: 5px 30px !important;
            }

            .toggle-back {
              background-color: white;
              width: 400px;
              padding: 4px;
              border-radius: 10px;

              .toggle-btn {
                color: #475467;
                padding: 0px !important;
                padding: 5px !important;
                width: 50%;
                border: white;
              }

              .toggle-btn:hover {
                color: #475467;
                padding: 0px !important;
                padding: 5px !important;
                width: 50%;
                border: white;
              }

              .toggle-btn-color {
                padding: 5px !important;
                width: 50%;
                background: #007dc6;
                color: white;
                border: white;
                border-radius: 10px;
              }

              .toggle-btn-color:hover {
                padding: 5px !important;
                width: 50%;
                background: #007dc6;
                color: white;
                border: white;
                border-radius: 10px;
              }
            }
          }

          .heading-div {
            display: flex;
            align-items: center;
            padding-bottom: 7px;

            .cal-img {
              padding-right: 10px;
            }
          }

          .padl-26 {
            padding-left: 26px;
          }

          .order-text {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }

          .order-text1 {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .purchase-div {
        //   padding: 10px 20px 0px 20px;
        margin-bottom: 30px;

        .purchasetext {
          font-style: normal;
          font-weight: 325;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
        }
      }

      .sub-classbox {
        margin: 0px 23px;
        overflow-y: auto !important;
        background: white;
        width: auto;
        display: flex;
        padding-bottom: 14px;

        .sub-project {
          min-width: 30%;
          width: 30%;
          padding: 10px 0px;
          background-color: whitesmoke;
          border-radius: 10px;
          margin-left: 10px;

          .sub-project-div {
            padding-left: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 6px;

            .sub-project-text {
              display: block;
              font-style: normal;
              font-weight: 350;
              font-size: 15px;
              line-height: 20px;
              color: #101828;
              padding-bottom: 5px;
              font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            }

            .sub-project-img {
              padding-right: 10px;
            }
          }

          .sub-project-text1 {
            font-style: normal;
            font-weight: 325;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            margin-left: 11px;
          }
        }
      }

      .purchase-div-padding {
        padding: 10px 20px 0px 20px !important
      }

      .purchase-div {
        padding: 10px 20px 0px 40px;
        background-color: white;
        margin-bottom: 20px !important;

        // display: flex;
        // align-items: center;
        .ordered {
          padding-left: 26px;

          .order-text2 {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
          }

          .calender-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            padding: 15px;
            background-color: #f9f9f9;
            border-radius: 8px;
            margin-bottom: 15px;

            .month {
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 20px;
              color: #475467;
              font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
            }

            .date {
              font-style: normal;
              font-weight: 325;
              font-size: 15px;
              line-height: 23px;
              font-family: "Gotham-Light", "Courier New", Courier, monospace;
              color: #475467;
            }
          }
        }
      }
      .delesect-tag001{
        padding: 0px 20px 0px 49px;
        font-weight: 100;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;

      }

      .bottom-div {
        background: #d2efff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;

        .save-btn {
          background: #007dc6;
          color: white;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          border-radius: 10px;
        }

        .save-text {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: #00649e;
          font-family: "Gotham-light", "Courier New", Courier, monospace;
        }

        .save-text-credit {
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 38px;
          color: #00649e;
        }
      }
    }

    .card-details {
      border-radius: 10px;
      margin-bottom: 20px;
      box-shadow: 0 0 4px #e1dede;

      .card-details-main {
        width: 100%;
        height: 100%;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        isolation: isolate;
        border-radius: 16px 16px 0px 0px;
        display: flex;
        overflow: hidden !important;
        justify-content: space-between;
        position: relative;

        .back-image {
          width: 100%;
        }

        .item-float {
          position: absolute;
          display: flex;
          overflow: hidden;
          justify-content: space-between;
          align-items: center;
          padding: 32px 24px;
          padding-right: 0px;
          width: inherit;
        }

        .icon-side {
          width: 20%;
          scale: 1.6;
        }

        .card-details-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .top-div {
          margin-bottom: 10px;
        }

        .card-qty {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;

          .card-qty1 {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          }
        }

        .card-details-name {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #ffffff;
          margin-left: 10px;
        }
      }

      .card-details-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 25px 20px 10px;
        background: #ecfdf3;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .card-details-btn {
          font-style: normal;
          font-weight: 350;
          font-size: 16px;
          line-height: 24px;
          color: #00649e;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        }

        .card-bottom-credit {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .card-credit {
            font-family: "Gotham-Light", "Courier New", Courier, monospace !important;

            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #027a48;
          }

          .card-credit-amt {
            color: #027a48;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
          }
        }
      }
    }
  }

  .main-right {
    .review-main {
      height: 100%;
      position: relative;
      border-radius: 10px;
      border: 1px solid #eaecf0;
      box-shadow: 0 0 4px #e1dede;
      background-color: white;
      margin: 18px 0px;

      .divone {
        padding: 20px 18px;

        .review-header {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #101828;
        }

        .info {
          display: flex;
          align-items: center;
          padding-top: 8px;

          .info-text1 {
            padding-right: 14px;
            font-weight: 400;
            font-size: 14px;
            line-height: 12px;
            color: #667085;
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
          }
        }
      }

      .info2 {
        display: flex;
        align-items: center;
        padding: 12px 20px;

        .info-text2 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #101828;
          padding-left: 15px;
        }
      }

      .info7 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;

        .info-text7 {
          font-style: normal;
          font-weight: 350;
          font-size: 14px;
          line-height: 20px;
          color: #242a32;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

        .info-text8 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #00649e;
        }



        .info-text9 {
          color: #475467;

          font-style: normal;
          font-weight: 325;
          font-size: 16px;
          line-height: 18px;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

       

        .info-text10 {
          font-style: normal;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: #475467;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

        .card1-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .credits-text {
            font-style: normal;
            font-weight: 325;
            font-size: 14px;
            line-height: 20px;
            color: #475467;
          }

          .credits-amt {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #007dc6;

            .credits-amt1 {
              font-family: "Gotham-Light", "Courier New", Courier, monospace;
            }
          }
        }
      }

      .info3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .info-text7 {
          font-style: normal;
          font-weight: 350;
          font-size: 14px;
          line-height: 20px;
          color: #242a32;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

        .info-text8 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #00649e;
        }

        .info-text9 {
          color: #475467;

          font-style: normal;
          font-weight: 325;
          font-size: 16px;
          line-height: 18px;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

        .info-text10 {
          font-style: normal;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: #475467;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

        .card1-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .credits-text {
            font-style: normal;
            font-weight: 325;
            font-size: 14px;
            line-height: 20px;
            color: #475467;
          }

          .credits-amt {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #007dc6;

            .credits-amt1 {
              font-family: "Gotham-Light", "Courier New", Courier, monospace;
            }
          }
        }
      }

      .info-bg {
        padding: 10px;
        background-color: whitesmoke;
        border-radius: 10px;
      }

      .divtwo {
        padding: 0px 27px 10px 27px;

        .info-text3 {
          font-style: normal;
          font-weight: 350;
          font-size: 16px;
          line-height: 20px;
          color: #242a32;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

        .info-div3 {
          margin-left: 20px;
          padding: 10px 0px;
          display: flex;
          flex-direction: column;

          .info-text4 {
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            color: #475467;
          }
        }
      }

      .divthree {
        display: flex;
        flex-direction: column;
        padding: 0px 27px 20px 27px;
        min-height: 700px;

        .date-div {
          display: flex;
          // flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 14px 12px;
          gap: 8px;
          background: #f7fbf8;
          border-radius: 10px;
          margin-top: 20px;

          .dates {
            font-style: normal;
            font-weight: 350;
            font-size: 14px;
            line-height: 20px;
            color: #242a32;
          }
        }

        .return-label {
          margin-top: 10px;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: #667085;
          font-style: Italic
        }

        .date-text {
          margin-top: 20px;

          .info-text2 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #101828;
            padding-left: 15px;
          }
        }

        .info-text7 {
          margin-bottom: 10px;
          font-style: normal;
          font-weight: 350;
          font-size: 16px;
          line-height: 20px;
          color: #242a32;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }

        .info-text8 {
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 22px;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;
          color: #475467;
          width: 60%;
        }

        .info-text9 {
          font-weight: 300;
          font-size: 14px;
          font-family: "Gotham-Light", "Courier New", Courier, monospace;
          color: #475467;
          font-style: italic;
          margin-top: 16px;
        }
        .conatat-change-bottom{
          margin-bottom: 20px
        }
        .contact-info-text {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          margin-bottom: 18px;

        }
      }

      .info-text5 {
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #667085;
      }

      .footer1 {
        bottom: 0px;
        left: 0px;
        right: 0px;
        position: absolute;
        padding: 12px 20px;
        padding-bottom: 0px;

        .right-btn1 {
          background-color: #667086;
          color: white;
          font-style: normal;
          font-weight: 350;
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          margin: 30px 0px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 24px;
          height: 48px;
          border-radius: 10px;
          gap: 16px;
          grid-gap: 16px;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        }

        .right-btn {
          font-style: normal;
          font-weight: 350;
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          margin: 30px 0px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 24px;
          height: 48px;
          background: #007dc6;
          border-radius: 10px;
          color: #ffffff;
          gap: 16px;
          grid-gap: 16px;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        }
      }
    }

  }
}
@media (min-width: 1090px) and (max-width: 1120px) {
  .divthree {
    min-height: 700px !important;
  }
}
@media (min-width: 1121px) and (max-width: 1144px) {
  .divthree {
    min-height: 670px !important;
  }
}
@media (min-width: 1145px) and (max-width: 1461px) {
  .divthree {
    min-height: 650px !important;
  }
}
@media (min-width: 1462px) and (max-width: 1571px) {
  .divthree {
    min-height: 610px !important;
  }
}
@media (min-width: 1572px){
  .divthree {
    min-height: 580px !important;
  }
}