.company-title {
  font-family: "Gotham", "Courier New", Courier, monospace;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
  text-align: left;
}

.company-container {
  width: auto;
  height: auto;
  left: 260px;
  padding: 40px 24px 40px 24px;
  gap: 32px;
}

.icon-btn-2 {
  width: 160px;
  height: 160px;
  gap: 0px;
  border-radius: 136px 0px 0px 0px;
}

.profile-avatar {
  width: 160px !important;
  height: 160px !important;
  background: rgba(0, 0, 0, 0.4) !important;
  border-radius: 1000px !important;
  position: relative;
}

.profile-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  position: absolute;
  width: 98px;
  height: 52px;
  left: 34px;
  top: 54px;
  visibility: hidden;
}

.icon-btn-2:hover .profile-avatar {
  outline: 10px solid rgba(63, 81, 181, 0.04);
  outline-offset: 0px; 
  border-radius: 10px;
}

.icon-btn-2:hover .nonvalid-profile-icon {
  opacity: 1;
  pointer-events: auto;
}

.profile-container .nonvalid-profile-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  position: absolute;
  width: 98px;
  height: 52px;
  left: 34px;
  top: 56px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.profile-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.company-name {
  margin-left: 15px;
  font-family: "Gotham", "Courier New", Courier, monospace;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #344054;
}
.tab-buttons-clicked, .tab-buttons {
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
  font-weight: bold;
  width: auto;
  height: 36px;
  padding: 10px 12px 30px 12px;
  gap: 6px; 
  border-radius: 8px;
  margin-right: 20px; 
}

.tab-buttons-clicked {
  background-color: #007dc6;
  color: #ffffff;
}

.tab-buttons {
  border-radius: 8px 0 0 0;
  color: #475467;
  background-color: #ffffff;
  font-weight: 400;
}

.company-data-container {
  width: 98%;
  height: auto;
  padding: 40px 24px;
  gap: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 0 4px #e1dede !important;
}

.company-data {
  font-family: Gotham;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #344054;
}
.textfeild-label {
  color: black;
  margin-top: 20px;
  margin-bottom: 8px;
}
.textfeild-label-mail {
  font-style: italic;
}
.textfeild-company {
  width: 530px;
}
.save-button {
  width: 160px;
  height: 48px;
  padding: 10px 16px 10px 16px;
  gap: 8px;
  border-radius: 10px;
  background-color: #007dc6;
  color: white;
}



@media screen and (max-width: 921px) {
  .textfeild-company {
    width: 98% !important;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 450px) {
  .company-container {
    padding: 40px 0px 40px 0px;
  }

  .company-name {
    font-size: 20px;
    line-height: 20px;
    width: 100px;
    word-wrap: break-word;
  }

  .company-title {
    font-size: 30px;
  }
}
.document-container {
  margin-top: 30px !important;
}
.pdf-container {
  width: 530px !important;
  height: 85px;
  padding: 0 0 24px 30px;
  border-radius: 8px;
  border: 1px solid #4676fb;
  border-style: dashed;
  margin-top: 20px;
}
.select-title {
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
  font-size: 16px;
  font-weight: 600;
  line-height: 15.31px;
  text-align: left;
  color: #3a3a49;
  letter-spacing: -0.02em;
  margin-left: 20px;
}

.select-sub-title {
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.25px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #64748b;
  margin-top: 10px;
  margin-left: 20px;
}

.select-file-button {
  width: auto;
  height: 40px;
  padding: 10px 12px 10px 12px;
  gap: 6px;
  border-radius: 8px;
  background-color: #007dc6;
  color: #ffffff;
  display: inline-block;
  margin-top: 23px;
}

.pdf-box {
  width: 530 !important;
  height: 56px;
  padding: 16px 10px 16px 16px;
  border-radius: 8px;
  border: 1px solid #eceef1;
  box-shadow: 0px 8px 32px 0px #0000000f;
  box-shadow: 0px 4px 8px 0px #00000008;
  margin-top: 20px;
}

@media screen and (max-width: 1240px) {
  .pdf-container {
    width: 98% !important;
  }
  .pdf-box {
    width: 98% !important;
  }
  .select-file-button {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .pdf-container {
    height: 140px;
    padding-bottom: 0px !important;
    padding-left: 2px !important;
  }
}
@media screen and (max-width: 655px) {
  .select-title {
    font-size: 13px;
  }
  .pdf-container {
    padding-left: 5px !important;
  }
}

@media screen and (max-width: 400px) {
  .pdf-container {
    padding-left: 5px !important;
    height: 160px;
  }
}
.file-upload-button-wrapper {
  position: relative;
  display: inline-block;
}
.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 95px;
  height: 40px;
  cursor: pointer;
  opacity: 0;
}
.pdfErrorMessage{
  color:red;
  margin-top: 10px;
 font-family:"Gotham-Light", "Courier New", Courier, monospace;
}
.company-data-title {
  font-family: Gotham;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #344054;
  margin-bottom: 20px;
}
.errorStyle{
  color: red;
  width: 50%;
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: blue !important;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: #F2F4F7 !important;
  }
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: black !important;
}
.MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline {
  border-color: blue !important;
}
.MuiInputBase-root input{
  font-family:"Gotham-Light";
  font-weight: 600 !important;
  color: #475467;
  ;
}
.round-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  background-color: #f0f4f7; /* Choose your desired background color */
  border-radius: 50%;
  margin-top: 20px !important;
}

.round-background img {
  width: 25px; /* Adjust as needed */
  height: 25px; /* Adjust as needed */
}
.name-container{
  margin-top: 25px !important;
}