.training-container {
  width: 100%;
  .pointer {
    width: 45%;
  }
  .infinite {
    height: fit-content !important;
  }
  .MuiLinearProgress-colorPrimary {
    background-color: #f9fafb !important;
  }
  .card-heading {
    font-family: "Gotham-Medium1";
    font-size: 16px;
    line-height: 20px;
    color: #007dc6;
    font-weight: 100;
    font-style: normal;
  }
  .card-subheading {
    font-family: "Gotham-Medium";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    color: #344054;
  }
  .card-icon {
    width: 100%;
    height: 154px;
    border-radius: 5px;
  }
  .logo-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .logo-icon {
    width: 28px;
  }
  .logo-div {
    position: relative;
  }
  .card-logoname {
    font-size: 16px;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    line-height: 30px !important;
    font-weight: 400;
    position: absolute;
    padding-left: 42px;
    color: #475467;
    //#9b9ea6
  }
  .hours-credit-div {
    position: relative;
    margin-top: 50px;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    flex-wrap: wrap;
  }
  .hours-chip {
    padding: 2px 6px;
    gap: 4px;
    background: #e8f6fe;
    color: #00649e;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }
  .credit-chip {
    padding: 2px 6px;
    gap: 4px;
    background: #f6fef9;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #039855;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }
  .dedit-chip {
    padding: 2px 6px;
    gap: 4px;
    background: #feede6;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #dc6803;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }
  .iconavatar {
    height: 16px !important;
    width: 16px !important;
  }
  .card-desc {
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #475467 !important;
  }
  .card-main {
    background: #ffffff;
    box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
      0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
      0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
      0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
      0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
  }
  .training-progressbar {
    height: 7px;
  }
  .training-progressbar .MuiLinearProgress-barColorPrimary {
    background-color: #69d584 !important;
  }
}
.MuiDialog-paperWidthSm {
  width: 600px;
  background: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
    0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
    0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
    0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
    0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
  border-radius: 16px;

  .training-div {
    padding: 0px !important;
  }
  .MuiDialogContent-root {
    flex: none !important;
    position: relative;
  }
}

.MuiPaper-rounded {
  border-radius: 16px !important;
}

.training-title-txt {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #007dc6;
}
.trainingModal-img {
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  position: relative;
}
.training-close-icon {
  position: absolute;
  right: 16px;
  top: 12px;
  cursor: pointer;
}
.training-subtitle-txt {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #242a32;
}
.training-desc-txt {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
}
.box-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  .box-content {
    display: flex;
    background-color: #f6f7f9;
    border-radius: 8px;
    align-items: center;
    padding: 16px;
  }
  .box-icon {
    height: 32px;
    width: 32px;
  }
  .training-icon-box {
    padding-left: 18px;
  }
  .training-icon-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  .training-icon-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #242a32;
  }
}
.training-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px !important;

  .right-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    height: 48px;
    background: #007dc6;
    border-radius: 10px;
    color: #ffffff;
    gap: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    height: 48px;
    background: #007dc6;
    border-radius: 10px;
    color: #ffffff;
    grid-gap: 16px;
    gap: 16px;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }
  .completed-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    grid-gap: 16px;
    gap: 16px;
    background: #ecfdf3;
    border: 1px solid #039855;
    border-radius: 10px;
    color: #039855;
  }
  .right-btn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    height: 48px;
    background: #007dc6;
    border-radius: 10px;
    color: #ffffff;
    gap: 16px;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }
}

.main-tab .active-tab {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #242a32;
  min-width: 45px;
  font-family: "Gotham", "Courier New", Courier, monospace;
  text-transform: none;
}
.main-tab .deactive-tab {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #98a2b3;
  min-width: 45px;
  font-family: "Gotham", "Courier New", Courier, monospace;
  text-transform: none;
}
.modal-logo-icon {
  width: 28px;
  position: absolute;
}
.modal-logo-div {
  position: relative;
  padding: 10px 0px 27px;
  display: flex;
  align-items: center;
}
.modal-logoname {
  font-size: 14px;
  font-family: "Gotham-Bold", "Courier New", Courier, monospace !important;
  line-height: 32px;
  font-weight: 400;
  position: absolute;
  padding-left: 38px;
  color: #475467;
  //#9b9ea6
}

.error-on-order {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 24px 1px 24px;
  background: #fef3f2;
  border-radius: 10px;
  margin-top: 25px !important;
  margin-bottom: 20px !important;
  .error-text {
    font-family: "Gotham-Medium", "Courier New", Courier, monospace;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #d92d20;
    padding-bottom: 12px;
    align-self: flex-start;
  }
}
