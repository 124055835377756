.MuiDialog-container {
  width: 100%;
}

.MuiDialog-paper {
  position: initial !important;
}

.container-comfirmed1 {
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;

  position: absolute;

  background: #ffffff;

  border-radius: 16px;
  border: 1px solid #eaecf0;
  box-shadow: 0 0 4px #e1dede;

  .img1 {
    position: absolute;
    top: -39px;
    align-self: center;
    display: flex;
    justify-content: center;
  }

  .request-top-div1 {
    text-align: center;
    padding: 37px 25px 25px 25px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: -moz-available;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .request-title1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 50px;
      color: #242a32;
    }

    .request-description1 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #344054;
      font-family: "Gotham-Light", "Courier New", Courier, monospace;

      .request-description2 {
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      }
    }
  }

  .top-div1 {
    background: #ecfdf3;
    text-align: center;
    padding: 37px 25px 25px 25px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: -moz-available;
    width: -webkit-fill-available;

    .title1 {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #05603a;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
    }

    .description1 {
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
      font-weight: 100;
      font-size: 14px;
      line-height: 20px;
      color: #027a48;

      .des-bold {
        font-family: "Gotham", "Courier New", Courier, monospace;
      }
    }
  }

  .bottom-div1 {
    width: 92%;
    padding: 25px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;

    .request-classbox-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #f2f4f7;
      border-radius: 10px;
      padding: 13px 0px;

      .avatar-card {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }

      .request-classbox-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        .name {
          font-style: normal;
          font-weight: 350;
          font-size: 16px;
          line-height: 24px;
          color: #475467;
        }

        .admin {
          font-style: normal;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: #007dc6;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }
      }

      .request-btn {
        background: #e8f6fe;
        border-radius: 1000px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #004b77;
        padding: 10px 15px;
        margin-right: 10px;
      }
    }

    .classbox-container {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .classbox-item-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background-color: whitesmoke;
        padding: 10px;
        border-radius: 10px;
        display: flex;

        .classbox-item-txt {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #242a32;

          .classbox-subtext {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
          }
        }

        .classbox-item-txt1 {
          font-size: 14px;
          line-height: 20px;
          color: #475467;

          font-family: "Gotham-Light", "Courier New", Courier, monospace;
        }
      }

      .classbox-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 45%;
        background-color: whitesmoke;
        padding: 10px;
        border-radius: 10px;
        display: flex;

        .classbox-item-txt {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #242a32;

          .classbox-subtext {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
          }
        }

        .classbox-item-txt1 {
          font-size: 14px;
          line-height: 20px;
          color: #475467;

          font-family: "Gotham-Light", "Courier New", Courier, monospace;
        }
      }
    }

    .download-style-underline {
      text-decoration: none;

      .download-btn {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        height: 48px;
        border-radius: 10px;
        color: #00649E !important;
        gap: 16px;
        grid-gap: 16px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
      }
    }

    .right-btn1 {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      height: 48px;
      background: #007dc6;
      border-radius: 10px;
      color: #ffffff;
      gap: 16px;
      grid-gap: 16px;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }
  }

  .bottom-div2 {
    width: 100%;
    padding: 25px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;

    .request-classbox-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #f2f4f7;
      border-radius: 10px;
      padding: 13px 0px;

      .avatar-card {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }

      .request-classbox-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        .name {
          font-style: normal;
          font-weight: 350;
          font-size: 16px;
          line-height: 24px;
          color: #475467;
        }

        .admin {
          font-style: normal;
          font-weight: 325;
          font-size: 12px;
          line-height: 18px;
          color: #007dc6;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
        }
      }

      .request-btn {
        background: #e8f6fe;
        border-radius: 1000px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #004b77;
        padding: 10px 15px;
        margin-right: 10px;
      }
    }

    .classbox-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .classbox-item-1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background-color: whitesmoke;
        padding: 10px;
        border-radius: 10px;
        display: flex;

        .classbox-item-txt {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #242a32;

          .classbox-subtext {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
          }
        }

        .classbox-item-txt1 {
          font-size: 14px;
          line-height: 20px;
          color: #475467;

          font-family: "Gotham-Light", "Courier New", Courier, monospace;
        }
      }

      .classbox-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 45%;
        background-color: whitesmoke;
        padding: 10px;
        border-radius: 10px;
        display: flex;

        .classbox-item-txt {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #242a32;

          .classbox-subtext {
            font-family: "Gotham-Light", "Courier New", Courier, monospace;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
          }
        }

        .classbox-item-txt1 {
          font-size: 14px;
          line-height: 20px;
          color: #475467;

          font-family: "Gotham-Light", "Courier New", Courier, monospace;
        }
      }
    }

    .download-btn {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      height: 48px;
      border-radius: 10px;
      color: #00649E !important;
      gap: 16px;
      grid-gap: 16px;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }

    .right-btn1 {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      height: 48px;
      background: #007dc6;
      border-radius: 10px;
      color: #ffffff;
      gap: 16px;
      grid-gap: 16px;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }
  }
}

.xavc1 {
  width: 40%;

  .icon-radius-of-round1212 > img {
    border-radius: 50%;
  }
}