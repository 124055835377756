.remove-style-root {
  overflow-x: unset !important;
}
.remove-style-root1 {
  overflow-x: unset !important;
}
.remove-style-root1 :first-child {
  overflow-x: unset !important;
}
.rs-picker-toggle-caret::before {
  content: " " !important;
  display: inline-block;
  font-family: "Courier New", Courier, monospace !important;
  width: 25px;
  height: 25px;
  background-image: url("../assets/calender.svg");
  background-size: cover;
}
.rs-picker-daterange .rs-picker-toggle-caret::before {
  color: transparent !important;
  content: " " !important;
}
.rs-picker-daterange-content {
  background-color: #ffffff;
}
.rs-picker-daterange-header {
  display: none;
}
.rs-picker-toggle-value,
.rs-picker-toggle-clean {
  color: transparent !important;
  width: 0px !important;
}
.rs-picker-toggle.rs-btn {
  padding-top: 13px !important;
  padding-right: 35px !important;
}
.rs-picker-toggle {
  color: transparent !important;
  width: 0px !important;
  width: 0px;
}
.rs-btn-sm {
  background: #f9fafb !important;
  border-radius: 1000px !important;
  color: #101828 !important;
  font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  font-style: normal !important;
  font-weight: 350 !important;
  font-size: 12px !important;
  text-decoration: none !important;
}

.rs-btn-sm:hover {
  background: #007dc6 !important;
  border-radius: 1000px !important;
  color: white !important;
  font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  font-style: normal !important;
  font-weight: 350 !important;
  font-size: 12px !important;
  text-decoration: none !important;
}

.rs-calendar-table-cell-content {
  font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
}

.rs-picker-toolbar-right {
  display: none;
  width: 300px;
}
.rs-picker-menu {
  border-radius: 8px;
  overflow: hidden !important;
}
.date-picker-component {
  .rs-picker {
  }

  .rs-anim-fade {
    position: absolute;
  }
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: transparent !important;
}
.rs-anim-fade {
  .rs-picker-daterange-header {
    display: none !important;
  }

  .rs-btn-xs {
    color: #454545 !important;
  }

  .rs-btn-xs:hover {
    line-height: 12px;
    border-radius: 50%;
    color: #454545 !important;

    .rs-icon {
      font-size: 24px !important;
    }
  }

  .rs-calendar-header-title {
    padding-top: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #454545;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }

  .rs-calendar-header-title:hover {
    padding-top: 5px 0px 0px 0px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 27px !important;
    color: #454545;
    background-color: inherit !important;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }

  .rs-icon {
    font-size: 24px !important;
  }

  .rs-calendar-table-header-cell-content {
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #737373;
  }

  .rs-calendar-table-header-cell {
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 20px;
    color: #454545;
  }

  .rs-calendar-table-cell-content:hover {
    .rs-calendar-table-header-cell {
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 325;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      border-radius: 50%;
      background: #0099f3 !important;
    }
  }

  .rs-calendar-table-cell-selected {
    .rs-calendar-table-cell-content {
      color: #ffffff;
      background-color: #0099f3 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 50%;
    }

    .rs-calendar-table-cell-content:hover {
      color: #ffffff;
      background-color: #0099f3 !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 50%;
    }
  }

  .rs-calendar-table-cell-is-today {
    .rs-calendar-table-cell-content {
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      border-radius: 50%;
      box-shadow: unset;
    }

    .rs-calendar-table-cell-content:hover {
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-radius: 50%;
      box-shadow: unset;
      background-color: #f9fafb;
    }
  }

  .rs-calendar-table-cell-is-today:hover {
    .rs-calendar-table-cell-content {
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      border-radius: 50%;
      box-shadow: unset;
    }

    .rs-calendar-table-cell-content:hover {
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      border-radius: 50%;
      box-shadow: unset;
      background-color: #f9fafb;
    }
  }
}

.rs-picker-menu {
  .rs-calendar-header-title,
  .rs-calendar-header-meridian,
  .rs-calendar-header-backward,
  .rs-calendar-header-forward {
    color: #454545 !important;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    font-size: 14px;
    font-style: normal !important;
    font-weight: 600 !important;
  }
  .rs-calendar-table-header-row {
    .rs-calendar-table-cell-content {
      color: #737373;
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-size: 13px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      text-decoration: none;
    }
  }
  .rs-calendar-table-cell-disabled,
  .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled,
  .rs-calendar-table-cell-content {
    text-decoration: none !important;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-size: 13px !important;
    color: #101828;
    font-weight: 800 !important;
  }
  .rs-calendar-table-cell-is-today {
    border: 0px !important;
    text-decoration: none !important;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-size: 13px !important;
    color: #101828;
    font-weight: 800 !important;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today
    .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content {
    opacity: 1 !important;
    border: 0px !important;
    text-decoration: none !important;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-size: 13px !important;
    color: #c5c6c7;
    font-weight: 800 !important;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #ffffff;
    border-color: #3498ff;
    background-color: #3498ff;
    border-radius: 50%;
  }
  .rs-calendar-table-cell-in-range::before {
    background-color: #d2efff;
  }
  .rs-calendar-table-cell-selected::before,
  .rs-calendar-table-cell-in-range::before {
    content: "";
    display: block;
    width: 100%;
    height: 32px;
    position: absolute;
    z-index: 0;
    top: 0;
    margin-top: 0px !important;
  }
  .rs-picker-menu .rs-calendar-table-cell-selected::before,
  .rs-picker-menu .rs-calendar-table-cell-in-range::before {
    background-color: #d2efff;
  }
  .rs-calendar-table-cell-selected-start::before {
    background-color: #d2efff !important;
    border-top-left-radius: 86%;
    border-bottom-left-radius: 73%;
    margin-left: 3px;
  }
  .rs-calendar-table-cell-selected-end::before {
    background-color: #d2efff !important;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  .rs-calendar .rs-calendar-table-cell-content {
    margin: 4px !important;
    margin-top: 0px !important;
  }
  .rs-picker-toolbar-option {
    border-radius: 1000px;
    background: #f9fafb;
    color: #101828;
    font-family: "Gotham-Light";
    font-weight: 600;
    padding: 5px 16px;
    font-size: 13px;
  }
  .rs-picker-toolbar-option:hover {
    border-radius: 1000px;
    background: #007dc6;
    color: #fff;
    font-family: "Gotham-Light";
    font-weight: 600;
    padding: 5px 16px;
    font-size: 13px;
    text-decoration: none;
  }
  .rs-picker-toolbar-ranges {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .rs-picker-toolbar-option:not(:last-child)::before {
    content: unset;
  }
}
