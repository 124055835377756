.order-modal {
  .MuiDialog-paperWidthSm {
    max-width: unset !important;
    width: 50%;
  }
  .header-order-modal {
    background: #feede6;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 18px;
    .mb-1 {
      margin-bottom: 1px;
    }
    .header-txt {
      font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #dc6803;
    }
  }
  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 24px 20px 5px 20px !important;
  }
  .MuiTypography-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .order-modal-title {
    font-family: "Gotham", "Courier New", Courier, monospace !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 38px !important;
    color: #242a32 !important;
    letter-spacing: unset !important;
  }
  .order-modal-content {
    .tabs {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px;
      background: #f9fafb;
      border-radius: 10px;
      margin: 0px !important;

      .MuiGrid-item {
        padding: 2px;
      }
      .activebtn {
        background: #007dc6;
        padding: 12px 25px;
        border-radius: 8px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
      }

      .nonactivebtn {
        background: #f9fafb;
        padding: 12px 25px;
        border-radius: 8px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #475467;
        display: flex;
        justify-content: center;
      }

      .nonactivebtn:hover {
        background: #007dc6;
        padding: 12px 25px;
        border-radius: 8px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 350;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
      }
    }
    .description-txt {
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
      padding: 18px 0px;
    }
    .box-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      background: #fcfcfd;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .blue-box {
        background: #d2efff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0px 0px;
        align-self: stretch;
        .txt-des {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #004b77;
        }
      }
      .right-box {
        margin: 0px;
        padding: 60px 20px 60px 30px;
        .right-container {
          margin: 0px;
          .txt-fonts {
            font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #242a32;
            padding: 6px !important;
          }
          .right-align {
            display: flex;
            justify-content: flex-end;
            align-self: flex-end;
          }
        }
      }
    }
    .checkout-one-month-tab {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      .title001 {
        padding-top: 30px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #475467;
      }
      .title002 {
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #00649e;
      }
      .title003 {
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        color: #00649e;
      }
    }
    .price-container {
      margin: 0px;
      padding-left: 40%;
      padding-top: 16px;
      .divider0 {
        width: 100%;
        background-color: #f9fafb;
        margin-bottom: 4px;
        height: 2px;
      }
      .right-container {
        margin: 0px;
        display: flex;
        align-items: center;
        .txt-fonts {
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #242a32;
          padding: 6px !important;
        }
        .MuiGrid-item {
          padding: 2px !important;
        }
        .right-align {
          display: flex;
          justify-content: flex-end;
          align-self: flex-end;
          .txt0 {
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #00649e;
            text-decoration-line: line-through;
          }
          .txt1 {
            font-family: "Gotham-Bold", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #00649e;
          }
          .txt2 {
            font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #00649e;
          }
          .txt3 {
            font-family: "Gotham-Bold", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #00649e;
          }
          .txt4 {
            font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #00649e;
          }
          .text-tag {
            padding: 4px 12px;
            background: #ecfdf3;
            border-radius: 1000px;
            font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #039855;
          }
        }
      }
    }
  }
  .order-btn-container {
    padding: 16px 23px;

    .left-btn-order {
      padding: 10px 30px;
      font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #475467;
    }
    .right-btn-order {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      background: #007dc6;
      border-radius: 10px;
      color: white;
      font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
    }
  }
}
