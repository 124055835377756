.accordion-main-divxyz {
    border-radius: 10px;
    border: 1px solid #F2F4F7 !important;
    background: #FFF;
    padding: 4px !important;

}

.MuiMenu-paper {
    .divider-unset {
        margin: unset;
    }

    .standard-placeholderxyz {
        color: red;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-left: 10px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    }

    .standard-selected-placeholder {
        margin-left: 10px;
        color: #344054;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }

    .standard-select-name {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .MuiPaper-rounded {
        // box-shadow: unset !important;
        border-radius: 0px !important;
    }

    .standard-list-text {
        color: #344054;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin: 5px 0px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    }

    .standard-list-text-checked {
        color: #344054;
        // color: red;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        margin: 5px 0px;
    }
}

.standard-placeholderxyz1 {
    color: #344054;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-left: 12px;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
}


.standard-selected-placeholderxyz1 {
    color: #344054;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 10px;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
}