.invite-user-modal {
    .modal-title {
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 30px !important;
        line-height: 38px !important;
        color: #242A32 !important;
        padding-bottom: 0px !important;
    }

    .modal-description {
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
        color: #667085 !important;
    }

    .input-container {
        .input-label {
            padding-bottom: 5px;
            font-style: normal;
            font-weight: 350;
            font-size: 16px;
            line-height: 24px;
            color: #101828;
        }

        .input-field {
            width: 100%;

            .MuiOutlinedInput-input {
                padding: 12px;
                width: 100%;
                height: 12px;
                background: #FFFFFF;
                border: 1px solid #F2F4F7;
                border-radius: 10px;
            }
        }
    }

    .cancle-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 16px;
        width: 127px;
        height: 40px;
        border-radius: 8px;
    }

    .add-user-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        grid-gap: 16px;
        gap: 16px;
        width: 127px;
        height: 40px;
        background: #007DC6;
        border-radius: 8px;
    }
}
