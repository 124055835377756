.inviteuser-container {
    background-color: #D2EFFF;
    overflow: hidden;

    .page-header {
        background-color: #D2EFFF;

        .logo {
            padding: 10px 20px;
        }
    }

    .page-body {
        background-color: #D2EFFF;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            font-style: normal;
            text-align: center;
            font-weight: 700;
            font-size: 72px;
            line-height: 90px;
            letter-spacing: -0.02em;
            color: #004B77;
        }

        .invite-user-btn {
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 44px;
            letter-spacing: -0.02em;
            color: #007DC6;
        }
    }
}
